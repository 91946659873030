.ant-form {
  &-item {
    margin-bottom: 1em;
  }
}

.ant-btn {
  align-items: center;
  border-radius: 1.25em;
  display: inline-flex;
  justify-content: center;
  line-height: 1;

  .anticon {
    display: inherit;
  }

  &-flat {
    background: none;
    border: none;
    box-shadow: none;

    &:hover {
      box-shadow: @box-shadow-base;
      color: inherit;
    }
  }
}

.ant-list {
  .ant-list-item {
    &.discontinued {
     opacity: .5;
    }
  }
}

.ant-table {
  background: white;
  border-radius: .5em;
  box-shadow: @box-shadow-base;

  @media (max-width: 480px) {
    border-radius: 0;
    margin-left: -1em;
    margin-right: -1em;
  }

  &-thead, &-tbody {
    > tr {
      > th, td {
        font-size: .9em;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }
  }

  &-thead {
    > tr {
      > th, > td {
        background-color: #FDFDFD;
        border-bottom: 1px solid #F2F2F2;
        color: #999;
        font-weight: 400;

        &:first-child {
          border-radius: .26em 0 0 0;
        }

        &:last-child {
          border-radius: 0 .26em 0 0;
        }
      }
    }
  }

  &-tbody {
    > tr {
      > td {
        border-color: #FAFAFA;
        cursor: pointer;
      }

      &.ant-table-expanded-row:hover {
        > td {
          background-color: #FEFEFE;
          cursor: default;
        }
      }

      &:last-child > td {
        border-bottom: none;
      }

      &.discontinued {
        opacity: .5;

        > td {
          background-color: #F6F6F6;
        }
      }
    }
  }

  &-body {
    @media (max-width: 480px) {
      overflow-x: auto;
    }
  }

  &-expanded-row, &-expanded-row:hover {
    td {
      background-color: #FEFEFE;
    }
  }

  &-row-expand-icon {
    pointer-events: none;
  }

  &-footer {
    background-color: #FCFCFC;
    color: rgba(0, 0, 0, .35);
    position: relative;

    &::after, &::before {
      background: linear-gradient(to bottom, black, transparent);
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &::after {
      height: 1em;
      opacity: .0125;
    }

    &::before {
      height: .25em;
      opacity: .025;
    }
  }

  &-expanded-row {
    td, td:hover {
      background-color: #FAFAFA;
      position: relative;

      &::before, &::after {
        position: absolute;
        pointer-events: none;
        content: '';
        left: 0;
        right: 0;
        height: .5em;
        opacity: .025;
      }

      &::before {
        top: 0;
        background: linear-gradient(to bottom, black, transparent);
      }

      &::after {
        bottom: 0;
        background: linear-gradient(to top, black, transparent);
      }
    }
  }
}

.ant-collapse {
  &-content {
    > .ant-collapse {
      &-content-box {
        padding: 0;
      }
    }
  }

  &-paddingless {
    .ant-collapse {
      &-content {
        > .ant-collapse {
          &-content-box {
            padding: 0;
          }
        }
      }
    }

    > .ant-collapse-item {
      border: none;

      > .ant-collapse {
        &-header {
          align-items: center;
          color: lighten(@text-color, 35%);
          display: flex;
          padding: .5em 0;

          .anticon {
            margin-right: 1em;
            position: static;
            transform: none;
          }
        }
      }
    }
  }
}

.ant-modal {
  @media (max-width: 480px) {
    margin: 0;
    max-width: 100%;
    top: 0;

    &-content {
      border-radius: 0;
    }

    &-footer {
      bottom: 0;
      position: sticky;
    }
  }

  &-mask {
    background-color: rgba(0, 0, 0, .75);
  }

  &-header {
    border: none;
  }

  &-title {
    color: @text-color;
    font-weight: 300;
  }

  &-footer {
    background: #FAFAFA;
    border: none;
    box-shadow: @box-shadow-inset;
    padding: 1em 1.7em;

    .ant-btn:not(.ant-btn-primary) {
      background: none;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: @box-shadow-base;
        color: inherit;
      }
    }
  }
}

.ant-descriptions {
  &-bordered {
    .ant-descriptions {
      @media (max-width: 480px) {
        &-row {
          display: flex;
          flex-direction: column;
        }

        &-item-label {
          border-right: none;
        }
      }
    }
  }
}

.ant-select {
  ul {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100%;
    overflow-y: hidden;
  }

  &-selection {
    &__choice {
      flex-shrink: 0;
    }
    &--multiple {
      .ant-select {
        &-search {
          &--inline {
            flex-grow: 1;
            float: none;

            .ant-select-search__field {
              width: unset;

              &__wrap {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

.recharts {
  transform: translate3d(0, 0, 0);

  &-tooltip-cursor {
    fill: rgba(0, 0, 0, .125);
  }

  &-cartesian {
    &-grid {
      line {
        stroke: rgba(0, 0, 0, .125);
      }
    }

    &-axis-tick {
      .recharts-text {
        font-size: .8em;
        mix-blend-mode: multiply;
      }
    }
  }

  &-legend-wrapper {
    mask-image: linear-gradient(to bottom, transparent, white 5%, white 95%, transparent);
    max-height: 100%;
    overflow-y: auto;
  }

  @media (max-width: 480px) {
    &-default-legend {
      max-height: 4.75em;
    }
  }
}

.rsm-geographies {
  transform: translate(50%, 50%) scale(2.2, 2.5) rotateX(180deg);

  path {
    outline: none;
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: rgba(0, 0, 0, .0125);
  border: 2px dashed rgba(0, 0, 0, .125);
  border-radius: .5em;
  opacity: 1;
}

@media print {
  body, html {
    margin: 0;
    padding: 0;
  }

  .ant-layout-sider,
  .ant-layout-header {
    display: none !important;
  }
}
@primary-color: #4B4DF1;@success-color: #83DEAD;@warning-color: #FFCA28;@error-color: #F44336;@box-shadow-base: 0 .125em .25em rgba(0, 0, 0, .125), 0 1em 2em rgba(75, 77, 241, .1);@box-shadow-hover: 0 .25em .5em rgba(0, 0, 0, .125), 0 1em 2em rgba(75, 77, 241, .15);@box-shadow-inset: inset 0 .25em .25em -.25em rgba(0, 0, 0, .125), inset 0 1em 1em -1em rgba(75, 77, 241, .1);@text-color: #37474F;@border-color-base: #37474F11;